exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contentful-programs-title-js": () => import("./../../../src/pages/{ContentfulPrograms.title}.js" /* webpackChunkName: "component---src-pages-contentful-programs-title-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-ourteam-js": () => import("./../../../src/pages/ourteam.js" /* webpackChunkName: "component---src-pages-ourteam-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-successpage-js": () => import("./../../../src/pages/successpage.js" /* webpackChunkName: "component---src-pages-successpage-js" */),
  "component---src-pages-termsconditions-js": () => import("./../../../src/pages/termsconditions.js" /* webpackChunkName: "component---src-pages-termsconditions-js" */),
  "component---src-pages-trustees-js": () => import("./../../../src/pages/trustees.js" /* webpackChunkName: "component---src-pages-trustees-js" */)
}

